<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Tabela de Arquivos da Entidade</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-size-100 text-left"></div>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <span class="space-tool-bar"></span>
              <md-field>
                <md-input
                  type="search"
                  clearable
                  style="width: 250px"
                  placeholder="Pesquisar"
                  v-model="searchQuery"
                >
                </md-input> </md-field
              >( Pesquise por Nome Completo ou CPF ou Data ou Nome Arquivo ou
              Pasta/Objeoto )
              <span class="space-tool-bar"></span>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell
                md-label="Nome Completo"
                md-sort-by="nomeCompleto"
                >{{
                  item.nomeCompleto && item.nomeCompleto !== null
                    ? item.nomeCompleto
                    : "N/A"
                }}</md-table-cell
              >
              <md-table-cell md-label="CPF" md-sort-by="cpf">{{
                cpfMaskCall(item.cpf && item.cpf !== null ? item.cpf : "N/A")
              }}</md-table-cell>
              <md-table-cell md-label="Data" md-sort-by="dataModificacao">{{
                item.dataModificacao.split(" ")[0]
              }}</md-table-cell>
              <md-table-cell md-label="versão" md-sort-by="versao">{{
                item.versao && item.versao !== null ? item.versao : "N/A"
              }}</md-table-cell>
              <md-table-cell md-label="Nome Arquivo" md-sort-by="nomeArquivo">{{
                item.nomeArquivo && item.nomeArquivo !== null
                  ? item.nomeArquivo
                  : "N/A"
              }}</md-table-cell>

              <md-table-cell md-label="Pasta/Objeto" md-sort-by="nomePasta">{{
                item.nomePasta && item.nomePasta !== null
                  ? item.nomePasta
                  : "N/A"
              }}</md-table-cell>
              <md-table-cell md-label="Ações" :class="getAlignClasses(item)">
                <md-button
                  class="md-just-icon md-success md-round"
                  @click="
                    downloadId(item.idArquivo, item.nomeArquivo, item.versao)
                  "
                  ><md-icon>get_app</md-icon></md-button
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.nomePapel"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { Pagination } from "@/components";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";
import { cpfMask } from "@/pages/Dashboard/js/cpfMask";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import {
  download,
  instance
} from "../../arquivo/js/file-upload.download.service";
import * as axios from "axios";
import { getLocalToken } from "@/modules/auth/storage";

const axiosInstance = instance;

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    Pagination
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile,

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.tableData.length > 0) {
        if (this.searchedData.length > 0) {
          result = this.searchedData;
        }
        return result.slice(this.from, this.to);
      } else {
        return [];
      }
    },
    to() {
      if (this.tableData.length > 0) {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      } else {
        return 0;
      }
    },
    from() {
      if (this.tableData.length > 0) {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      } else {
        return -1;
      }
    },
    total() {
      if (this.tableData.length > 0) {
        return this.searchedData.length > 0
          ? this.searchedData.length
          : this.tableData.length;
      } else {
        return 0;
      }
    }
  },
  data() {
    return {
      mensagem: "",
      idPapel: "",
      isFetching: true,
      classicModal: false,
      papel: "",
      currentSort: "nomeArquivo",
      currentSortOrder: "desc",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100, 200, 500, 1000],
        total: 0
      },
      footerTable: [
        "Nome Completo",
        "CPF",
        "Data",
        "versão",
        "Nome Arquivos",
        "Pasta/Objeto",
        "Ações"
      ],
      searchQuery: "",
      propsToSearch: [
        "nomeCompleto",
        "cpf",
        "nomeArquivo",
        "nomePasta",
        "dataModificacao"
      ],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  beforeMount() {
    this.getPapel();
    this.getArquivoFromEntidadeById();
    const token = getLocalToken();
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },
  methods: {
    cpfMaskCall(value) {
      return cpfMask(value);
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    ...mapActions("papel", ["ActionGetPapelAll"]),
    ...mapActions("arquivo", ["ActionGetFromEntidadeById"]),
    getAlignClasses: ({ id }) => ({
      "text-right": id
    }),
    downloadId(id, nome, versao) {
      download(axiosInstance, id, nome, versao, this.papel);
    },
    async getArquivoFromEntidadeById() {
      try {
        let papel = this.papel;
        let id = this.user_profile_state.idEntidade;
        await this.ActionGetFromEntidadeById({ id, papel }).then(response => {
          if (response.status === 200) {
            if (response.body.mensagem && response.body.mensagem !== null) {
              //console.log(response.body.mensagem);
              this.tableData = [];
            } else {
              this.tableData = response.body;
            }
            //this.isFetching = false;
            return response.body;
          }
        });
      } catch (err) {
        this.handleError(err);
        //console.log(err);
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].toString().localeCompare(b[sortBy].toString());
        }
        return b[sortBy].toString().localeCompare(a[sortBy].toString());
      });
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  mounted() {
    this.isFetching = false;
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        this.fuseSearch = new Fuse(result, {
          keys: this.propsToSearch,
          threshold: 0.09
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.fuseSearch = null;
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 600;
}

::v-deep .md-table table {
  font-weight: 500;
}

.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-weight: 400;
  font-size: 0.65rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
::v-deep .modal-container {
  max-width: 720px;
}

::v-deep .md-table-cell:last-child .md-table-cell-container {
  padding-right: 15px;
}

.modal-text {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

::v-deep .md-count {
  display: none;
}

#agrupamentoCampos {
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 5px;
  padding-top: 12px;
  padding-bottom: 12px;
}

::v-deep .md-radio.md-disabled .md-radio-label,
.md-radio.md-disabled .md-radio-container {
  opacity: 1;
  border-color: rgba(0, 0, 0, 1) !important;
}

.uppercase {
  text-transform: uppercase;
}

.md-just-icon {
  height: 27px;
  min-width: 27px;
  width: 27px;
}

.paginated-table table > tbody > tr > td {
  max-width: 150px;
  width: 0px;
}
</style>
